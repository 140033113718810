import { getMerchantColour } from './merchantCustomization';

const injectMerchantStyles = (merchant: any, defaultValue = '#3d5cff') => {
  const id = 'injected-merchant-styles';
  const parent = document.head;
  document.querySelectorAll(`#${id}`).forEach((e) => parent.removeChild(e));

  if (merchant) {
    const color = getMerchantColour(merchant, defaultValue);
    const style = `
.merchant-button {
  background-color: ${color} !important;
  border-color: ${color} !important;
}
.merchant-background {
  background-color: ${color} !important;
}
.merchant-border {
  border-color: ${color} !important;
}
.merchant-color {
  color: ${color} !important;
}
.merchant-background-transparent-20 {
  background-color: ${color}20 !important;
}
.merchant-input-switch.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background-color: ${color};
}
.merchant-fill {
  fill: ${color};
}

.merchant-input-switch.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background-color: ${color};
}

.merchant-carousel {
  .p-carousel-content {
    .p-carousel-indicators {
      .p-highlight {
        .p-link {
          background-color: ${color} !important;
        }
      }
    }
  }
}

.slider-color {
  .p-slider-range {
    background: ${color};
  }
  .p-slider-handle {
    border-color: ${color};
  }
  .p-slider:not(.p-disabled) .p-slider-handle:hover {
    border-color: ${color};
  }
}

.p-inputtext:focus {
  border-color: ${color} !important;
}

.p-inputtext:hover {
  border-color: ${color} !important;
}

/*overrides*/

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: ${color} !important;
}
.p-radiobutton-box.p-highlight,
.p-radiobutton-box.p-highlight:focus,
.p-radiobutton-box.p-highlight:hover {
  border-color: ${color} !important;
  background: #E6E9FC !important;
  box-shadow: none !important;
  border-width: 2px !important;
}
.p-radiobutton-icon {
  background: ${color} !important;
  width: 8px !important;
  height: 8px !important;
}
 {
}
    `;
    const styles = document.createElement('style');
    styles.id = id;
    styles.innerHTML = style;
    parent.appendChild(styles);
  }
};

export { injectMerchantStyles };
