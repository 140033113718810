export type CountryCode = { code: string; flag: string; name: string };

export const countries: CountryCode[] = [
  { code: '+27', flag: 'flag flag-za', name: 'South Africa' },
  { code: '+44', flag: 'flag flag-gb', name: 'Great Britain' },
  { code: '+1', flag: 'flag flag-us', name: 'United States' },
  { code: '+213', flag: 'flag flag-dz', name: 'Algeria' },
  { code: '+376', flag: 'flag flag-ad', name: 'Andorra' },
  { code: '+244', flag: 'flag flag-ao', name: 'Angola' },
  { code: '+1264', flag: 'flag flag-ai', name: 'Anguilla' },
  { code: '+1268', flag: 'flag flag-ag', name: 'Antigua and Barbuda' },
  { code: '+54', flag: 'flag flag-ar', name: 'Argentina' },
  { code: '+374', flag: 'flag flag-am', name: 'Armenia' },
  { code: '+297', flag: 'flag flag-aw', name: 'Aruba' },
  { code: '+61', flag: 'flag flag-au', name: 'Australia' },
  { code: '+43', flag: 'flag flag-at', name: 'Austria' },
  { code: '+994', flag: 'flag flag-az', name: 'Azerbaijan' },
  { code: '+1242', flag: 'flag flag-bs', name: 'Bahamas' },
  { code: '+973', flag: 'flag flag-bh', name: 'Bahrain' },
  { code: '+880', flag: 'flag flag-bd', name: 'Bangladesh' },
  { code: '+1246', flag: 'flag flag-bb', name: 'Barbados' },
  { code: '+375', flag: 'flag flag-by', name: 'Belarus' },
  { code: '+32', flag: 'flag flag-be', name: 'Belgium' },
  { code: '+501', flag: 'flag flag-bz', name: 'Belize' },
  { code: '+229', flag: 'flag flag-bj', name: 'Benin' },
  { code: '+1441', flag: 'flag flag-bm', name: 'Bermuda' },
  { code: '+975', flag: 'flag flag-bt', name: 'Bhutan' },
  { code: '+591', flag: 'flag flag-bo', name: 'Bolivia' },
  { code: '+387', flag: 'flag flag-ba', name: 'Bosnia and Herzegovina' },
  { code: '+267', flag: 'flag flag-bw', name: 'Botswana' },
  { code: '+55', flag: 'flag flag-br', name: 'Brazil' },
  { code: '+673', flag: 'flag flag-bn', name: 'Brunei' },
  { code: '+359', flag: 'flag flag-bg', name: 'Bulgaria' },
  { code: '+226', flag: 'flag flag-bf', name: 'Burkina Faso' },
  { code: '+257', flag: 'flag flag-bi', name: 'Burundi' },
  { code: '+855', flag: 'flag flag-kh', name: 'Cambodia' },
  { code: '+237', flag: 'flag flag-cm', name: 'Cameroon' },
  { code: '+238', flag: 'flag flag-cv', name: 'Cabo Verde' },
  { code: '+1345', flag: 'flag flag-ky', name: 'Cayman Islands' },
  { code: '+236', flag: 'flag flag-cf', name: 'Central African Republic' },
  { code: '+56', flag: 'flag flag-cl', name: 'Chile' },
  { code: '+86', flag: 'flag flag-cn', name: 'China' },
  { code: '+57', flag: 'flag flag-co', name: 'Colombia' },
  { code: '+269', flag: 'flag flag-km', name: 'Comoros' },
  { code: '+242', flag: 'flag flag-cd', name: 'The Bahamas' },
  { code: '+682', flag: 'flag flag-ck', name: 'Cook Islands' },
  { code: '+506', flag: 'flag flag-cr', name: 'Costa Rica' },
  { code: '+385', flag: 'flag flag-hr', name: 'Croatia' },
  { code: '+53', flag: 'flag flag-cu', name: 'Cuba' },
  { code: '+357', flag: 'flag flag-cy', name: 'Cyprus' },
  { code: '+42', flag: 'flag flag-cz', name: 'Czech Republic' },
  { code: '+45', flag: 'flag flag-dk', name: 'Denmark' },
  { code: '+253', flag: 'flag flag-dj', name: 'Djibouti' },
  { code: '+1809', flag: 'flag flag-dm', name: 'Dominican Republic' },
  { code: '+593', flag: 'flag flag-ec', name: 'Ecuador' },
  { code: '+20', flag: 'flag flag-eg', name: 'Egypt' },
  { code: '+503', flag: 'flag flag-sv', name: 'El Salvador' },
  { code: '+240', flag: 'flag flag-gq', name: 'Equatorial Guinea' },
  { code: '+291', flag: 'flag flag-er', name: 'Eritrea' },
  { code: '+372', flag: 'flag flag-ee', name: 'Estonia' },
  { code: '+251', flag: 'flag flag-et', name: 'Ethiopia' },
  { code: '+500', flag: 'flag flag-fk', name: 'Falkland Islands' },
  { code: '+298', flag: 'flag flag-fo', name: 'Faroe Islands' },
  { code: '+679', flag: 'flag flag-fj', name: 'Fiji' },
  { code: '+358', flag: 'flag flag-fi', name: 'Finland' },
  { code: '+33', flag: 'flag flag-fr', name: 'France' },
  { code: '+689', flag: 'flag flag-pf', name: 'French Polynesia' },
  { code: '+241', flag: 'flag flag-ga', name: 'Gabon' },
  { code: '+220', flag: 'flag flag-gm', name: 'Gambia' },
  { code: '+7880', flag: 'flag flag-ge', name: 'Georgia' },
  { code: '+49', flag: 'flag flag-de', name: 'Germany' },
  { code: '+233', flag: 'flag flag-gh', name: 'Ghana' },
  { code: '+350', flag: 'flag flag-gi', name: 'Gibraltar' },
  { code: '+30', flag: 'flag flag-gr', name: 'Greece' },
  { code: '+299', flag: 'flag flag-gl', name: 'Greenland' },
  { code: '+1473', flag: 'flag flag-gd', name: 'Grenada' },
  { code: '+671', flag: 'flag flag-gu', name: 'Guam' },
  { code: '+502', flag: 'flag flag-gt', name: 'Guatemala' },
  { code: '+224', flag: 'flag flag-gn', name: 'Guinea' },
  { code: '+245', flag: 'flag flag-gw', name: 'Guinea-Bissau' },
  { code: '+592', flag: 'flag flag-gy', name: 'Guyana' },
  { code: '+509', flag: 'flag flag-ht', name: 'Haiti' },
  { code: '+504', flag: 'flag flag-hn', name: 'Honduras' },
  { code: '+36', flag: 'flag flag-hu', name: 'Hungary' },
  { code: '+354', flag: 'flag flag-is', name: 'Iceland' },
  { code: '+91', flag: 'flag flag-in', name: 'India' },
  { code: '+62', flag: 'flag flag-id', name: 'Indonesia' },
  { code: '+98', flag: 'flag flag-ir', name: 'Iran' },
  { code: '+964', flag: 'flag flag-iq', name: 'Iraq' },
  { code: '+353', flag: 'flag flag-ie', name: 'Ireland' },
  { code: '+972', flag: 'flag flag-il', name: 'Israel' },
  { code: '+39', flag: 'flag flag-it', name: 'Italy' },
  { code: '+1876', flag: 'flag flag-jm', name: 'Jamaica' },
  { code: '+81', flag: 'flag flag-jp', name: 'Japan' },
  { code: '+962', flag: 'flag flag-jo', name: 'Jordan' },
  { code: '+254', flag: 'flag flag-ke', name: 'Kenya' },
  { code: '+850', flag: 'flag flag-kp', name: 'North Korea' },
  { code: '+82', flag: 'flag flag-kr', name: 'South Korea' },
  { code: '+965', flag: 'flag flag-kw', name: 'Kuwait' },
  { code: '+996', flag: 'flag flag-kg', name: 'Kyrgyzstan' },
  { code: '+856', flag: 'flag flag-la', name: 'Laos' },
  { code: '+371', flag: 'flag flag-lv', name: 'Latvia' },
  { code: '+961', flag: 'flag flag-lb', name: 'Lebanon' },
  { code: '+266', flag: 'flag flag-ls', name: 'Lesotho' },
  { code: '+231', flag: 'flag flag-lr', name: 'Liberia' },
  { code: '+218', flag: 'flag flag-ly', name: 'Libya' },
  { code: '+417', flag: 'flag flag-li', name: 'Liechtenstein' },
  { code: '+370', flag: 'flag flag-lt', name: 'Lithuania' },
  { code: '+352', flag: 'flag flag-lu', name: 'Luxembourg' },
  { code: '+853', flag: 'flag flag-mo', name: 'Macao' },
  { code: '+389', flag: 'flag flag-mk', name: 'North Macedonia' },
  { code: '+261', flag: 'flag flag-mg', name: 'Madagascar' },
  { code: '+265', flag: 'flag flag-mw', name: 'Malawi' },
  { code: '+60', flag: 'flag flag-my', name: 'Malaysia' },
  { code: '+960', flag: 'flag flag-mv', name: 'Maldives' },
  { code: '+223', flag: 'flag flag-ml', name: 'Mali' },
  { code: '+356', flag: 'flag flag-mt', name: 'Malta' },
  { code: '+692', flag: 'flag flag-mh', name: 'Marshall Islands' },
  { code: '+222', flag: 'flag flag-mr', name: 'Mauritania' },
  { code: '+52', flag: 'flag flag-mx', name: 'Mexico' },
  { code: '+691', flag: 'flag flag-fm', name: 'Micronesia' },
  { code: '+373', flag: 'flag flag-md', name: 'Moldova' },
  { code: '+377', flag: 'flag flag-mc', name: 'Monaco' },
  { code: '+976', flag: 'flag flag-mn', name: 'Mongolia' },
  { code: '+1664', flag: 'flag flag-ms', name: 'Montserrat' },
  { code: '+212', flag: 'flag flag-ma', name: 'Morocco' },
  { code: '+258', flag: 'flag flag-mz', name: 'Mozambique' },
  { code: '+95', flag: 'flag flag-mm', name: 'Myanmar' },
  { code: '+264', flag: 'flag flag-na', name: 'Namibia' },
  { code: '+674', flag: 'flag flag-nr', name: 'Nauru' },
  { code: '+977', flag: 'flag flag-np', name: 'Nepal' },
  { code: '+31', flag: 'flag flag-nl', name: 'Netherlands' },
  { code: '+687', flag: 'flag flag-nc', name: 'New Caledonia' },
  { code: '+64', flag: 'flag flag-nz', name: 'New Zealand' },
  { code: '+505', flag: 'flag flag-ni', name: 'Nicaragua' },
  { code: '+227', flag: 'flag flag-ne', name: 'Niger' },
  { code: '+234', flag: 'flag flag-ng', name: 'Nigeria' },
  { code: '+683', flag: 'flag flag-nu', name: 'Niue' },
  { code: '+670', flag: 'flag flag-mp', name: 'Northern Mariana Islands' },
  { code: '+47', flag: 'flag flag-no', name: 'Norway' },
  { code: '+968', flag: 'flag flag-om', name: 'Oman' },
  { code: '+680', flag: 'flag flag-pw', name: 'Palau' },
  { code: '+507', flag: 'flag flag-pa', name: 'Panama' },
  { code: '+675', flag: 'flag flag-pg', name: 'Papua New Guinea' },
  { code: '+595', flag: 'flag flag-py', name: 'Paraguay' },
  { code: '+51', flag: 'flag flag-pe', name: 'Peru' },
  { code: '+63', flag: 'flag flag-ph', name: 'Philippines' },
  { code: '+48', flag: 'flag flag-pl', name: 'Poland' },
  { code: '+351', flag: 'flag flag-pt', name: 'Portugal' },
  { code: '+1787', flag: 'flag flag-pr', name: 'Puerto Rico' },
  { code: '+974', flag: 'flag flag-qa', name: 'Qatar' },
  { code: '+262', flag: 'flag flag-re', name: 'Réunion' },
  { code: '+40', flag: 'flag flag-ro', name: 'Romania' },
  { code: '+7', flag: 'flag flag-ru', name: 'Russia' },
  { code: '+250', flag: 'flag flag-rw', name: 'Rwanda' },
  { code: '+378', flag: 'flag flag-sm', name: 'San Marino' },
  { code: '+239', flag: 'flag flag-st', name: 'Sao Tome and Principe' },
  { code: '+966', flag: 'flag flag-sa', name: 'Saudi Arabia' },
  { code: '+221', flag: 'flag flag-sn', name: 'Senegal' },
  { code: '+381', flag: 'flag flag-rs', name: 'Serbia' },
  { code: '+248', flag: 'flag flag-sc', name: 'Seychelles' },
  { code: '+232', flag: 'flag flag-sl', name: 'Sierra Leone' },
  { code: '+65', flag: 'flag flag-sg', name: 'Singapore' },
  { code: '+421', flag: 'flag flag-sk', name: 'Slovakia' },
  { code: '+386', flag: 'flag flag-si', name: 'Slovenia' },
  { code: '+677', flag: 'flag flag-sb', name: 'Solomon Islands' },
  { code: '+252', flag: 'flag flag-so', name: 'Somalia' },
  { code: '+34', flag: 'flag flag-es', name: 'Spain' },
  { code: '+94', flag: 'flag flag-lk', name: 'Sri Lanka' },
  { code: '+249', flag: 'flag flag-sr', name: 'Sudan' },
  { code: '+597', flag: 'flag flag-sr', name: 'Suriname' },
  { code: '+268', flag: 'flag flag-sz', name: 'Swaziland' },
  { code: '+46', flag: 'flag flag-se', name: 'Sweden' },
  { code: '+41', flag: 'flag flag-ch', name: 'Switzerland' },
  { code: '+963', flag: 'flag flag-sy', name: 'Syria' },
  { code: '+886', flag: 'flag flag-tw', name: 'Taiwan' },
  { code: '+255', flag: 'flag flag-tz', name: 'Tanzania' },
  { code: '+66', flag: 'flag flag-th', name: 'Thailand' },
  { code: '+228', flag: 'flag flag-tg', name: 'Togo' },
  { code: '+676', flag: 'flag flag-to', name: 'Tonga' },
  { code: '+1868', flag: 'flag flag-tt', name: 'Trinidad and Tobago' },
  { code: '+216', flag: 'flag flag-tn', name: 'Tunisia' },
  { code: '+90', flag: 'flag flag-tr', name: 'Turkey' },
  { code: '+993', flag: 'flag flag-tm', name: 'Turkmenistan' },
  { code: '+1649', flag: 'flag flag-tc', name: 'Turks and Caicos Islands' },
  { code: '+688', flag: 'flag flag-tv', name: 'Tuvalu' },
  { code: '+256', flag: 'flag flag-ug', name: 'Uganda' },
  { code: '+380', flag: 'flag flag-ua', name: 'Ukraine' },
  { code: '+971', flag: 'flag flag-ae', name: 'United Arab Emirates' },
  { code: '+598', flag: 'flag flag-uy', name: 'Uruguay' },
  { code: '+998', flag: 'flag flag-uz', name: 'Uzbekistan' },
  { code: '+678', flag: 'flag flag-vu', name: 'Vanuatu' },
  { code: '+379', flag: 'flag flag-va', name: 'Vatican City' },
  { code: '+58', flag: 'flag flag-ve', name: 'Venezuela' },
  { code: '+84', flag: 'flag flag-vn', name: 'Vietnam' },
  { code: '+681', flag: 'flag flag-wf', name: 'Wallis and Futuna' },
  { code: '+967', flag: 'flag flag-ye', name: 'Yemen' },
  { code: '+260', flag: 'flag flag-zm', name: 'Zambia' },
  { code: '+263', flag: 'flag flag-zw', name: 'Zimbabwe' },
];

var DEFAULT_PHONE_EXTENSION: string;

export const getDefaultCountry = (retailer: {
  default_phone_extension: string;
}) => {
  if (retailer?.default_phone_extension) {
    try {
      return JSON.parse(retailer.default_phone_extension);
    } catch {
      return retailer.default_phone_extension;
    }
  }

  if (DEFAULT_PHONE_EXTENSION) {
    try {
      return JSON.parse(DEFAULT_PHONE_EXTENSION);
    } catch {
      return DEFAULT_PHONE_EXTENSION;
    }
  }

  return countries[0];
};
