import axios from 'axios';
import Ping from 'ping.js';

const isDevelopment = () => {
  return (
    window.location.hostname.includes('localhost') ||
    window.location.hostname.includes('stage.vulacoin') ||
    window.location.hostname.includes('dev.vulacoin') ||
    window.location.hostname.includes('test.vulacoin')
  );
};

const isLocal = () => window.location.hostname.includes('localhost');

const isProduction = () => !isDevelopment();

function randomString(length = 8) {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const MINUTE_IN_SECONDS = 60;
export const HOUR_IN_SECONDS = 60 * MINUTE_IN_SECONDS;
export const DAY_IN_SECONDS = 24 * HOUR_IN_SECONDS;

export var pingStrategy;
(function (pingStrategy) {
  pingStrategy[(pingStrategy['IGNORE_ERRORS'] = 0)] = 'IGNORE_ERRORS';
  pingStrategy[(pingStrategy['RETRY_ERROR'] = 1)] = 'RETRY_ERROR';
  pingStrategy[(pingStrategy['MAX_FAILED'] = 2)] = 'MAX_FAILED';
  pingStrategy[(pingStrategy['SIMPLE'] = 3)] = 'SIMPLE';
})(pingStrategy || (pingStrategy = {}));

export async function ping(
  server,
  strategy = pingStrategy.SIMPLE,
  pingCount = 5,
  giveup = 30,
) {
  if (strategy === pingStrategy.SIMPLE) {
    server = server ? server : window.location.origin;
    return new Promise((resolve) => {
      new Ping({ favicon: '/dl.png' }).ping(server, (error, result) => {
        resolve(result * (56 / 525)); // scaling factor: ping packet size / actual image size
      });
    }).catch((error) => {
      console.error(error);
    });
  }

  let pongsReceived = 0;
  let lastPongTime;
  let lastPingTime;
  let ping = 99999; //Smallest ping
  let jitter = 0; //Average jitter
  let errors = 0;
  let adjustedErrors = 0;
  let giveupErrors = 0;
  while (pingCount > pongsReceived && giveupErrors < giveup) {
    await fetch(server + '?r=' + Math.random(), {
      mode: 'no-cors',
    })
      .then((value) => {
        pongsReceived++;
        if (lastPongTime == null) {
          //First ping
          lastPongTime = performance.now();
        } else {
          let instantPing = performance.now() - lastPongTime;
          lastPongTime = performance.now();
          if (instantPing < ping) {
            ping = instantPing;
          }
          if (lastPingTime != null) {
            let instantJitter = Math.abs(instantPing - lastPingTime);
            jitter += instantJitter;
          }
          lastPingTime = ping;
        }
      })
      .catch((reason) => {
        errors++;
        switch (strategy) {
          case pingStrategy.IGNORE_ERRORS:
            adjustedErrors++;
            break;
          case pingStrategy.MAX_FAILED:
            giveupErrors++;
            break;
          case pingStrategy.RETRY_ERROR:
            break;
        }
      });
  }
  jitter /= pingCount - adjustedErrors;
  return {
    ping: ping,
    jitter: jitter,
    errors: errors,
  };
}

export async function pingSimple(host, pong = null) {
  return new Promise((resolve, reject) => {
    const started = new Date();
    const http = new XMLHttpRequest();
    http.open('GET', host, true);
    http.onreadystatechange = function () {
      if (http.readyState === 4) {
        const ended = new Date();
        const res = ended.getTime() - started.getTime();
        if (pong) pong(ended - started);

        return res;
      }
    };
    http.addEventListener('load', () => {
      if (http.readyState === 4) {
        const ended = new Date().getTime();
        const res = ended - started;
        if (pong) {
          pong(res);
          resolve(res);
        }
      }
    });
    try {
      http.send(null);
    } catch (exception) {
      reject(exception);
      // this is expected
    }
  }).catch((error) => {
    console.error(error);
  });
}

export async function downloadTest(server, fileSize = 200000) {
  server = server ? server : `${window.location.origin}/api/health-check`;
  let startTime = 0;
  let endTime = 0;

  return new Promise((resolve, _) => {
    startTime = performance.now();
    axios.get(server).then((value) => {
      endTime = performance.now();
      const duration = (endTime - startTime) / 1000;
      // Convert bytes into bits by multiplying with 8
      const bitsLoaded = fileSize * 8;
      resolve(bitsLoaded / duration / 1000);
    });
  }).catch((error) => {
    console.error(error);
  });
}

export async function uploadTest(server, fileSizeInBytes = 200000) {
  let startTime = 0;
  let endTime = 0;
  const defaultData = randomString(fileSizeInBytes / 1000);
  const data = JSON.stringify({ defaultData });

  return new Promise((resolve, reject) => {
    startTime = performance.now();
    axios.post(server, data).then((value) => {
      endTime = performance.now();
      const duration = (endTime - startTime) / 1000;
      const bitsLoaded = fileSizeInBytes * 8;
      resolve(bitsLoaded / duration / 1000);
    });
  }).catch((error) => {
    console.error(error);
  });
}

const fullAnalytics = async () => {
  const pingResult = await ping();
  const download = await downloadTest();
  return { ping: pingResult, download };
};

const slack = (message, user = null) => {
  let text = `*MESSAGE FROM FRONT END* :boom:\n\t${message}`;

  if (user) {
    text = `*MESSAGE FROM FRONT END: ${user.details.cell_number}* :boom:\n\t${message}`;
  }
  return axios
    .post(
      'https://hooks.slack.com/services/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',
      { text },
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    )
    .catch(() => null);
};

const sleep = async (t = 1000) =>
  new Promise((r) => setTimeout(r, t)).catch((error) => {
    console.error(error);
  });

const assertConnection = () => {
  return axios
    .get('https://jsonapi.org', {
      headers: { 'Cache-Control': 'nocache' },
    })
    .catch((e) => {
      if (e.message === 'Network Error') {
        console.log('No network detected');
        if (
          // !isLocal() &&
          !window.location.pathname.includes('captive')
        ) {
          window.location.href = 'http://neverssl.com';
        }
      }
    });
};
window.assertConnection = assertConnection;
// assertConnection().then(() => null);
window.isProduction = isProduction;

const setMerchantFavicons = (res) => {
  let manifestURL = `${window.location.origin}/manifest.json?v=3`;
  if (!!res.currentRetailer && !!res.currentRetailer.logo) {
    try {
      const logo = res.currentRetailer.logo;
      Array.from(
        document.querySelectorAll('[data-group="dyn-favicon"]'),
      ).forEach((e) => (e.href = res.currentRetailer.logo));

      const dynManifestObj = {
        name: res.currentRetailer.name,
        short_name: res.currentRetailer.name,
        icons: [
          {
            src: logo,
            sizes: '192x192',
            type: 'image/png',
            purpose: 'any maskable',
          },
          {
            src: logo,
            sizes: '512x512',
            type: 'image/png',
            purpose: 'any maskable',
          },
        ],
        background_color: '#3367D6',
        display: 'fullscreen',
        scope: '/',
        start_url: '/',
        theme_color: '#3367D6',
        description: 'VulaCoin - Time is money',
      };

      const stringManifest = JSON.stringify(dynManifestObj);
      const blob = new Blob([stringManifest], { type: 'application/json' });
      manifestURL = URL.createObjectURL(blob);
    } catch (e) {
      console.error(e);
    }
  }
  document
    .querySelector('[data-group="dyn-manifest"]')
    .setAttribute('rel', 'manifest');
  document
    .querySelector('[data-group="dyn-manifest"]')
    .setAttribute('href', manifestURL);
};

const onLogin = (res) => {
  setMerchantFavicons(res);
};

const formatMac = (mac) => {
  return `${mac}`.replace(/:/g, '-').toUpperCase();
};

const VAT = 0.15;

const AthenaQueryState = {
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
  QUEUED: 'QUEUED',
  RUNNING: 'RUNNING',
  CANCELLED: 'CANCELLED',
};

const athenaStillRunning = (state) =>
  [AthenaQueryState.RUNNING, AthenaQueryState.QUEUED].includes(state);

/**
 * Usage example:
 * @see fetchTotalRecordsByNumber
 * const { state, queryResults } = awaitRadiusResponsePoll(fetchTotalRecordsByNumber, '+27....', '12345')
 * Note: there is no need to explicitly pass the queryExecutionId to fetchTotalRecordsByNumber
 * when calling this function
 * @param callback: API method
 * @param params
 * @returns {Promise<unknown>}
 */
const awaitRadiusResponsePoll = async (callback, ...params) => {
  return new Promise(async (resolve, reject) => {
    // First call returns ex
    const response = await callback.apply(null, params);

    if (athenaStillRunning(response.state)) {
      const maxTries = 30;
      const { queryExecutionId } = response;

      const functionParams = [...params, queryExecutionId];
      let attempts = 0;
      const interval = setInterval(async () => {
        if (attempts >= maxTries) {
          clearInterval(interval);
          reject({ message: 'Max attempts exceeded' });
          return;
        }
        const response = await callback.apply(null, functionParams);
        attempts += 1;

        if (
          [AthenaQueryState.QUEUED, AthenaQueryState.RUNNING].includes(
            response.state,
          )
        ) {
          return;
        }

        clearInterval(interval);
        if (response.state === AthenaQueryState.SUCCEEDED) {
          resolve(response);
          return;
        }

        reject(response);
      }, 2000);
    }
  });
};

/**
 * very wrong
 **/
const calculateDynamicBracketRate = (minutes) => {
  if (minutes <= 100) {
    return minutes;
  }
  if (minutes <= 1440) {
    return (
      100 +
      Math.round((1 / 2) * (minutes - 100)) +
      Math.round((1 / 3) * Math.max(0, minutes - 200)) +
      Math.round((1 / 4) * Math.max(0, minutes - 300)) +
      Math.round((1 / 4) * Math.max(0, minutes - 400)) +
      Math.round((1 / 5.5) * Math.max(0, minutes - 400))
    );
  }
};

const calculateDynamicBracketMinutes = (
  vulaAmount = 0,
  scale = 1,
  debug = false,
) => {
  const min = 60 * scale;
  const x = [
    Math.min(min, vulaAmount), // 60, 120
    Math.min(min, Math.max(0, vulaAmount - 60 * scale)),
    Math.min(min, Math.max(0, vulaAmount - 120 * scale)),
    Math.min(min, Math.max(0, vulaAmount - 180 * scale)),
    Math.min(min, Math.max(0, vulaAmount - 240 * scale) * 2),
    Math.min(min, Math.max(0, vulaAmount - 270 * scale) * 2),
    Math.min(min, Math.max(0, vulaAmount - 300 * scale) * 2),
    Math.min(min, Math.max(0, vulaAmount - 330 * scale) * 5),
    Math.min(min, Math.max(0, vulaAmount - 342 * scale) * 5),
    Math.min(min, Math.max(0, vulaAmount - 354 * scale) * 5),
    Math.min(min, Math.max(0, vulaAmount - 366 * scale) * 5),
    Math.min(min, Math.max(0, vulaAmount - 378 * scale) * 5),
    Math.min(min, Math.max(0, vulaAmount - 390 * scale) * 5),
    Math.min(min, Math.max(0, vulaAmount - 402 * scale) * 5),
    Math.min(min, Math.max(0, vulaAmount - 414 * scale) * 5),
    Math.min(min, Math.max(0, vulaAmount - 426 * scale) * 10),
    Math.min(min, Math.max(0, vulaAmount - 432 * scale) * 10),
    Math.min(min, Math.max(0, vulaAmount - 438 * scale) * 10),
    Math.min(min, Math.max(0, vulaAmount - 444 * scale) * 10),
    Math.min(min, Math.max(0, vulaAmount - 450 * scale) * 10),
    Math.min(min, Math.max(0, vulaAmount - 456 * scale) * 10),
    Math.min(min, Math.max(0, vulaAmount - 462 * scale) * 10),
    Math.min(min, Math.max(0, vulaAmount - 468 * scale) * 10),
    Math.min(min, Math.max(0, vulaAmount - 474 * scale) * 10),
  ];

  const minutes = x.reduce((acc, n) => acc + n, 0);
  if (debug) {
    return [{ values: x }, { result: minutes }];
  }
  return minutes;
};

const calculateConversionValue = (v, scale) => {
  if (v < 240 * scale) {
    return 1;
  } else if (v >= 240 * scale && v < 330 * scale) {
    return 2;
  } else if (v >= 330 * scale && v < 426 * scale) {
    return 5;
  } else {
    return 10;
  }
};

const testCalculateDynamicBracketMinutes = () => {
  const testData = [
    { input: 60, expected: 60 },
    { input: 120, expected: 120 },
    { input: 180, expected: 180 },
    { input: 240, expected: 240 },
    { input: 241, expected: 242 },
    { input: 270, expected: 300 },
    { input: 271, expected: 302 },
    { input: 300, expected: 360 },
    { input: 330, expected: 420 },
    { input: 331, expected: 425 },
    { input: 342, expected: 480 },
    { input: 354, expected: 540 },
    { input: 366, expected: 600 },
    { input: 378, expected: 660 },
    { input: 390, expected: 720 },
    { input: 402, expected: 780 },
    { input: 414, expected: 840 },
    { input: 426, expected: 900 },
    { input: 432, expected: 960 },
    { input: 438, expected: 1020 },
    { input: 444, expected: 1080 },
    { input: 450, expected: 1140 },
    { input: 456, expected: 1200 },
    { input: 462, expected: 1260 },
    { input: 468, expected: 1320 },
    { input: 474, expected: 1380 },
    { input: 480, expected: 1440 },
  ];

  const results = testData.map((data) => {
    const value = calculateDynamicBracketMinutes(data.input);
    return { ...data, value, result: data.expected === value ? '✓' : '𐄂' };
  });

  console.table(results);
};

export {
  formatMac,
  isDevelopment,
  isProduction,
  isLocal,
  randomString,
  sleep,
  fullAnalytics,
  slack,
  onLogin,
  setMerchantFavicons,
  VAT,
  AthenaQueryState,
  awaitRadiusResponsePoll,
  calculateDynamicBracketMinutes,
  calculateConversionValue,
};

export const formatCell = (cell) => {
  if (cell === undefined) {
    return '';
  }

  cell = cell.split(' ').join('');
  if (cell.charAt(0) === '+') {
    if (cell.length > 8) {
      return cell.replace(/(\d{4})(\d{3})(\d)/, '$1 $2 $3');
    }
    if (cell.length > 5) {
      return cell.replace(/(\d{4})(\d)/, '$1 $2');
    }
    return cell;
  }

  if (cell.length > 6) {
    return cell.replace(/(\d{3})(\d{3})(\d)/, '$1 $2 $3');
  }
  if (cell.length > 3) {
    return cell.replace(/(\d{3})(\d)/, '$1 $2');
  }
  return cell;
};

export const isMobile = () => {
  const regex =
    /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  return regex.test(navigator.userAgent) || window.innerWidth <= 991;
};

export const formatDurationOnSeconds = (seconds) => {
  const days = Math.floor(seconds / DAY_IN_SECONDS);
  seconds = seconds - days * DAY_IN_SECONDS;
  const hours = Math.floor(seconds / HOUR_IN_SECONDS);
  seconds = seconds - hours * HOUR_IN_SECONDS;
  const minutes = Math.floor(seconds / MINUTE_IN_SECONDS);
  return formatDuration(days, hours, minutes);
};

export const formatDuration = (days, hours, minutes) => {
  if (days > 0) {
    if (days === 1) return days + ' day';
    if (days > 1) return days + ' days';
  } else if (hours > 0) {
    if (hours === 1) return hours + ' hour';
    if (hours > 1) return hours + ' hours';
  } else if (minutes > 0) {
    if (minutes === 1) return minutes + ' minute';
    if (minutes > 1) return minutes + ' minutes';
  } else {
    return '';
  }
};
