import React, { useContext, useEffect, useRef, useState } from 'react';
import QRCode from 'qrcode.react';

function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
export const QRCodeCreate = (props) => {
  const count = getParameterByName('count') || 1;

  return (
    <div className="p-grid crud-demo qr-codes" style={{ marginTop: '10px' }}>
      <style>
        {`@media print {
        body {
          min-height: unset;
        }
        body #root,
        body div.qr-codes,
        body div.qr-codes * {
            visibility: visible;
        }`}
      </style>
      {[...Array(parseInt(count))].map((x, i) => (
        <QRCode
          style={{ margin: '10px' }}
          key={i}
          value={`https://${
            window.location.host
          }/send-tip/${crypto.randomUUID()}`}
          renderAs="svg"
        />
      ))}
    </div>
  );
};
