import 'react-app-polyfill/ie11';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './i18n';
import './theme/blue/theme-light.scss';
import './sass/layout-light.scss';
import './index.css';
import './Fonts/Poppins/Poppins-Bold.ttf';
import './Fonts/Poppins/Poppins-SemiBold.ttf';
import './Fonts/Poppins/Poppins-Regular.ttf';
import './Fonts/Poppins/Poppins-Medium.ttf';
import './Fonts/Vadodara/HindVadodara-Bold.ttf';
import './Fonts/Vadodara/HindVadodara-SemiBold.ttf';
import './Fonts/Vadodara/HindVadodara-Regular.ttf';
import './Fonts/Vadodara/HindVadodara-Medium.ttf';

import { fullAnalytics } from './utilities/general';
import AppWrapper from './AppWrapper';
import axios from 'axios';
import moment from 'moment';
//import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <BrowserRouter>
    <AppWrapper />
  </BrowserRouter>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

// const check = () => {
//     if (!('serviceWorker' in navigator)) {
//         throw new Error('No Service Worker support!')
//     }
//     if (!('PushManager' in window)) {
//         throw new Error('No Push API Support!')
//     }
//
// }

window.FA = fullAnalytics;
window.pingAndSpeedtestResults = fullAnalytics();
window.pushUserToDataLayer = (user) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'login',
    userId: user.cell_number, //this number must be replaced with an actual User ID
  });
};

window.gaSendNotify = (amount) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'send',
    sendValue: `${amount}`,
  });
};

window.gaTopUpNotify = (amount) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'topup',
    topupValue: `${amount}`,
  });
};

window.axios = axios;
window.moment = moment;

// window.xping = ping;
// window.xdownloadTest = downloadTest;
window.whosthebrowser = (function () {
  const ua = navigator.userAgent;
  let tem;
  let M =
    ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) ||
    [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return 'IE ' + (tem[1] || '');
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
  return M.join(' ');
})();
if (module.hot) {
  module.hot.accept();
}
