import apiClient from '../../../api-config/axios.config';
import { handleInterceptor } from '../../../utilities/handleHTTPInterceptor';
import axios from 'axios';

const saveMerchants = (ids = []) => {
  return apiClient
    .post('client/retailers', { retailer_ids: ids })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return handleInterceptor(error, 'saveMerchants failed');
    });
};

const fetchRetailer = (retailerHash) => {
  return apiClient
    .get(`v2/auth/retailers/by-hash/${retailerHash}`)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return handleInterceptor(error, 'fetchRetailer failed');
    });
};

const getMerchantsByLocation = (coordinates, filter, source) => {
  const config = {
    params: {
      latitude: coordinates.latitude,
      longitude: coordinates.longitude,
      filter: filter,
    },
  };
  if (source) {
    config.cancelToken = source.token;
  }
  return apiClient
    .get(`retailers/by-location`, config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return handleInterceptor(
        error,
        'getMerchantsByLocation failed',
        axios,
        true,
      );
    });
};

const fetchRetailerByCaptiveSubdomain = (subdomain) => {
  return apiClient
    .get(`get-retailer-by-captive-subdomain/${subdomain}`)
    .then((response) => {
      return response.data;
    });
};

const getRetailerLogos = (ids) => {
  return apiClient
    .get(`client/retailers/get-logos?ids=${ids}`)
    .then((response) => {
      return response.data;
    });
};

export {
  fetchRetailer,
  saveMerchants,
  getMerchantsByLocation,
  fetchRetailerByCaptiveSubdomain,
  getRetailerLogos,
};
