import { useEffect } from 'react';
import { Retailer } from '../../../common/definitions';
import axios from 'axios';
import moment from 'moment';

type HotspotDetails = {
  logged_in: any;
  ip: string;
  mac: string;
  hotspot_server: string;
};

type Props = {
  retailer: Retailer;
  setApMac?: (apMac: string) => void;
  setUserMac?: (apMac: string) => void;
};

const DetectHotspot = ({ retailer, setApMac, setUserMac }: Props) => {
  const params: any = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop: string) => searchParams.get(prop),
  });

  const fetchHotspotData = async (): Promise<HotspotDetails> => {
    const result = await axios
      .get(
        `${retailer.internal_captive_url}/details.html?v=${moment().unix()}`,
        {
          timeout: 5000,
        },
      )
      .then(async (data) => {
        if (typeof data.data === 'string') {
          try {
            return JSON.parse(data.data);
          } catch {
            return null;
          }
        }
        return data.data;
      })
      .catch((error) => {
        return null;
      });
    return result;
  };

  const checkForHotspot = () => {
    async function detectHotspot() {
      const data = await fetchHotspotData();
      if (!!data) {
        setApMac && setApMac(data.hotspot_server);
        setUserMac && setUserMac(data.mac);
        if (
          (typeof data.logged_in == 'string' && data.logged_in === 'false') ||
          (typeof data.logged_in == 'boolean' && data.logged_in === false)
        ) {
          window.location.href = retailer.internal_captive_url;
        }
      }
    }
    if (!!retailer?.internal_captive_url) {
      detectHotspot();
    }
  };

  useEffect(() => {
    try {
      if (params && !params.captive_redirect) {
        checkForHotspot();
      }
    } catch (e) {
      console.log(e);
    }
  }, [params]);

  return <></>;
};

export default DetectHotspot;
