import apiClient from '../../../api-config/axios.config';
import { handleInterceptor } from '../../../utilities/handleHTTPInterceptor';
import { AxiosResponse } from 'axios';

const baseURL = '/v2/tribes-service';

export interface Invitation {
  id: number;
  tribeId: number;
  fromClientId: number;
  fromClientCell: string;
  clientId?: number;
  clientCell: string;
  createdAt: Date;
  acceptedAt: Date;
  deletedAt: Date;
  toDetails?: { name: string; surname: string };
  fromDetails?: { name: string; surname: string };
}
export interface ApiResponse<T> {
  data?: T;
}
export interface PaginatedResponse<T> {
  records: T[];
  totalRecords: number;
  skip: number;
  take: number;
}

export interface BooleanResponse {
  success: boolean;
}

export interface ApiError {
  error: boolean;
  revoke?: boolean;
  message?: string;
  data?: any;
  code?: number;
}

export interface InvitationsResponse
  extends AxiosResponse<ApiResponse<PaginatedResponse<Invitation>>> {}

export function createInvite(dto: {
  cellNumber: string;
  retailerId: number;
}): Promise<Invitation | any> {
  return apiClient
    .post(`${baseURL}/invitations`, dto)
    .then(({ data: { data } }: AxiosResponse<ApiResponse<Invitation>>) => data)
    .catch((error) => handleInterceptor(error, error.message));
}

export function getInvites(
  status?: 'pending' | 'accepted' | 'rejected',
): Promise<PaginatedResponse<Invitation>> {
  return apiClient
    .get(`${baseURL}/invitations`, { params: { status } })
    .then(({ data: { data } }: InvitationsResponse) => data)
    .catch((error) => handleInterceptor(error, error.message));
}

export function acceptInvitation(invitationId: number) {
  return apiClient
    .post(`${baseURL}/accept-invitation`, { invitationId })
    .then(
      (response: AxiosResponse<ApiResponse<BooleanResponse>>) =>
        response.data.data,
    )
    .catch((error) => handleInterceptor(error, error.message));
}

export function declineInvitation(invitationId: number) {
  return apiClient
    .post(`${baseURL}/decline-invitation`, { invitationId })
    .then(
      (response: AxiosResponse<ApiResponse<BooleanResponse>>) =>
        response.data.data,
    )
    .catch((error) => handleInterceptor(error, error.message));
}

export interface Tribe {
  id: number;
}

export interface KickFromTribeRequest {
  cellNumber: string;
}

export interface Member {
  id: number;
  cell_number: string;
  name?: string;
  surname?: string;
  full_name?: string;
}

export interface MyTribeResponse {
  members: Member[];
  invitations: Invitation[];
}

export function getMyTribe(): Promise<MyTribeResponse> {
  return apiClient
    .get(`${baseURL}/my-tribe`)
    .then((response: AxiosResponse<ApiResponse<any>>) => response.data.data)
    .catch((error) => handleInterceptor(error, error.message));
}

export function getClientTribe(id: number): Promise<MyTribeResponse> {
  return apiClient
    .get(`${baseURL}/client-tribe/${id}`)
    .then((response: AxiosResponse<ApiResponse<any>>) => response.data.data)
    .catch((error) => handleInterceptor(error, error.message));
}

export function leaveTribe(): Promise<AxiosResponse<any>> {
  return apiClient
    .post(`${baseURL}/leave-tribe`)
    .then(
      (response: AxiosResponse<ApiResponse<BooleanResponse>>) =>
        response.data.data,
    )
    .catch((error) => handleInterceptor(error, error.message));
}

export function kickFromTribe(
  request: KickFromTribeRequest,
): Promise<AxiosResponse<any>> {
  return apiClient
    .post(`${baseURL}/kick-from-tribe`, request)
    .then(
      (response: AxiosResponse<ApiResponse<BooleanResponse>>) =>
        response.data.data,
    )
    .catch((error) => handleInterceptor(error, error.message));
}

export function dismissNotifications(
  type: string = 'all',
): Promise<AxiosResponse<any>> {
  return apiClient
    .post(`${baseURL}/dismiss-notifications`, { type })
    .then((response) => response.data.data)
    .catch((error) => handleInterceptor(error, error.message));
}
