import { parsePhoneNumber, getExample } from 'awesome-phonenumber';
import { countries } from './countries';

export const validateCell = (number, code) => {
  const country = countries.filter((c) => c.code === code);
  const region = country[0].flag.slice(-2).toUpperCase();
  const pn = parsePhoneNumber(number, region);
  const exampleNumber = getExample(region, 'mobile').getNumber('national');
  let startsCorrectly;
  switch (code) {
    case '+27': {
      startsCorrectly = number[0] === exampleNumber[0] ? true : false;
      break;
    }
    default: {
      startsCorrectly = true;
    }
  }
  return (
    !isNaN(number) &&
    pn.isValid() &&
    number.length === exampleNumber.replace(/\s/g, '').length &&
    startsCorrectly
  );
};
