import './PushNotification.scss';
import { getUserMerchantColour } from '../../../utilities/merchantCustomization';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';

export const DailyRecapNotification = (props) => {
  const history = useHistory();

  const data = props?.notification?.data
    ? JSON.parse(props?.notification?.data)
    : [];

  const dismiss = (e) => {
    props.setShow(false);
    props.setOldNotification(props.notification);
    props.setNotification(null);
    e.stopPropagation();
  };

  const onClick = () => {
    props.setShow(false);
    props.setNotification(null);
    history.push('daily-recap', {
      daily_recap: {
        totalData: data?.totalData,
        totalTime: data?.totalTime,
        totalSaved: data?.totalSaved,
        date: data?.date,
        rate: data?.rate,
      },
    });
  };

  return (
    <>
      <div
        className={
          props.show
            ? 'push-notification push-notification-show'
            : 'push-notification push-notification-hide'
        }
        style={{ borderColor: props.merchantColor }}
        onClick={() => {
          onClick();
        }}
      >
        <div
          className="header-container"
          style={{ background: getUserMerchantColour(props.user) }}
        >
          <div className="header-icon">
            <div className="inner-header-icon">
              <i id="icon" className={data?.icon ?? 'pi pi-calendar'} />
            </div>
          </div>
          <p className="header-text">{data?.header ?? 'Notification'}</p>
          <Button
            className="notification-close-btn"
            icon="pi pi-plus"
            onClick={(e) => {
              dismiss(e);
            }}
          />
        </div>
        <div
          className="message-container"
          style={{ borderColor: getUserMerchantColour(props.user) }}
        >
          <p className="message-text">
            {props.notification?.message || data?.message}
          </p>
        </div>
      </div>
    </>
  );
};

export default DailyRecapNotification;
