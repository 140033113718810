import { Fragment, useContext, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import UserContext from '../../Context/UserContext';
import { useTranslation } from 'react-i18next';
import { Skeleton } from 'primereact/skeleton';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { ProgressSpinner } from 'primereact/progressspinner';
import { createTicket } from '../service/api/help';
import { requestSubdomainRetailer } from '../service/api/clientAuth';
import CellInput from '../components/CellInput';
import useGeoLocation from '../service/helper/geolocation';
import { validateCell } from '../../utilities/cellValidator';
import { fetchRetailer } from '../../Client/service/api/retailers';
import './Help.scss';
import '../../Client/components/payments/QuestionnairePaymentScreen.scss';
import '../../Client/components/ClientHeader.scss';
import { getMerchantColour } from '../../utilities/merchantCustomization';
import { getDefaultCountry } from '../../utilities/countries';

const Help = (props) => {
  const history = useHistory();
  const location = useLocation();
  const geolocation = useGeoLocation();
  const { handleError, showToast } = props;
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const topUpProblems = t('help.topup', { returnObjects: true });
  const sendProblems = t('help.send', { returnObjects: true });
  const sellProblems = t('help.sell', { returnObjects: true });
  const connectionProblems = t('help.connection', { returnObjects: true });
  const generalProblems = t('help.general', { returnObjects: true });
  const purchaseProblems = t('help.purchase', { returnObjects: true });

  const [subdomainRetailer, setSubdomainRetailer] = useState(null);

  const [code, setCode] = useState(getDefaultCountry(user?.currentRetailer));

  const [sendNum, setSendNum] = useState(null);
  const [errors, setErrors] = useState({ number: null, amount: null });

  const [step, setStep] = useState(1);
  const [category, setCategory] = useState(null);
  const [categoryProblem, setCategoryProblem] = useState(null);
  const [problemDescription, setProblemDescription] = useState('');
  const [coordinates, setCoordinates] = useState();

  const fromPage =
    (location && location.state && location.state.fromPage) || null;
  const href = (location && location.state && location.state.href) || '';
  const purchasesPath =
    (location && location.state && location.state.purchasesPath) || null;
  const captivePath =
    (location && location.state && location.state.captivePath) || null;
  const captiveSearch =
    (location && location.state && location.state.captiveSearch) || null;

  useEffect(() => {
    if (captiveSearch) {
      const params = new Proxy(new URLSearchParams(captiveSearch), {
        get: (searchParams, prop) => searchParams.get(prop),
      });
      fetchRetailer(params.merchant_id).then((res) => {
        if (res.error) {
          handleError(res);
        } else {
          setSubdomainRetailer(res);
        }
      });
    } else {
      requestSubdomainRetailer().then((res) => {
        if (res.error) {
          handleError(res);
        } else {
          setSubdomainRetailer(res.currentRetailer);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (geolocation.loaded === true) {
      setCoordinates(geolocation.coordinates);
    }
  }, [geolocation.loaded]);

  const outputHeading = (step) => {
    switch (step) {
      case 1: {
        return t('help.category_heading');
      }
      case 2: {
        return t('help.problem_heading');
      }
      case 3: {
        // let isOptional = categoryProblem !== "Other" ? "(Optional)" : null
        // return t('help.description_heading', {isOptional: isOptional})
        return t('help.description_heading');
      }
      case 4: {
        return t('help.cell_heading');
      }
      default: {
        return null;
      }
    }
  };

  const outputCategories = () => {
    let categories;
    switch (fromPage) {
      default:
      case 'login': {
        categories = t('help.login_categories', { returnObjects: true });
        return Object.keys(categories).map((key, index) => {
          return (
            <div
              key={index}
              id="0"
              className={
                'card answer-option ' +
                (category === categories[key] ? 'help-answer-selected' : '')
              }
              style={
                category === categories[key]
                  ? {
                      background: getMerchantColour(subdomainRetailer, null),
                      borderColor: getMerchantColour(subdomainRetailer, null),
                    }
                  : null
              }
              onClick={() => {
                setCategory(categories[key]);
              }}
            >
              <p className="answer-option-label">
                {t(`help.login_categories.${key}`)}
              </p>
            </div>
          );
        });
      }
      case 'dashboard': {
        categories = t('help.dashboard_categories', { returnObjects: true });
        return Object.keys(categories).map((key, index) => {
          return (
            <div
              key={index}
              id="0"
              className={
                'card answer-option ' +
                (category === categories[key] ? 'help-answer-selected' : '')
              }
              style={
                category === categories[key]
                  ? {
                      background: getMerchantColour(subdomainRetailer, null),
                      borderColor: getMerchantColour(subdomainRetailer, null),
                    }
                  : null
              }
              onClick={() => {
                setCategory(categories[key]);
              }}
            >
              <p className="answer-option-label">
                {t(`help.dashboard_categories.${key}`)}
              </p>
            </div>
          );
        });
      }
      case 'paymentMethods': {
        categories = t('help.purchase_categories', { returnObjects: true });
        return Object.keys(categories).map((key, index) => {
          return (
            <div
              key={index}
              id="0"
              className={
                'card answer-option ' +
                (category === categories[key] ? 'help-answer-selected' : '')
              }
              style={
                category === categories[key]
                  ? {
                      background: getMerchantColour(subdomainRetailer, null),
                      borderColor: getMerchantColour(subdomainRetailer, null),
                    }
                  : null
              }
              onClick={() => {
                setCategory(categories[key]);
              }}
            >
              <p className="answer-option-label">
                {t(`help.purchase_categories.${key}`)}
              </p>
            </div>
          );
        });
      }
    }
  };

  const outputCategoryProblems = (category) => {
    switch (category.toLowerCase()) {
      case 'topup': {
        return Object.keys(topUpProblems).map((key, index) => {
          return (
            <div
              key={index}
              id="0"
              className={
                'card answer-option ' +
                (categoryProblem === topUpProblems[key]
                  ? 'help-answer-selected'
                  : '')
              }
              style={
                categoryProblem === topUpProblems[key]
                  ? {
                      background: getMerchantColour(subdomainRetailer, null),
                      borderColor: getMerchantColour(subdomainRetailer, null),
                    }
                  : null
              }
              onClick={() => {
                setCategoryProblem(topUpProblems[key]);
              }}
            >
              <p className="answer-option-label">{t(`help.topup.${key}`)}</p>
            </div>
          );
        });
      }
      case 'send': {
        return Object.keys(sendProblems).map((key, index) => {
          return (
            <div
              key={index}
              id="0"
              className={
                'card answer-option ' +
                (categoryProblem === sendProblems[key]
                  ? 'help-answer-selected'
                  : '')
              }
              style={
                categoryProblem === sendProblems[key]
                  ? {
                      background: getMerchantColour(subdomainRetailer, null),
                      borderColor: getMerchantColour(subdomainRetailer, null),
                    }
                  : null
              }
              onClick={() => {
                setCategoryProblem(sendProblems[key]);
              }}
            >
              <p className="answer-option-label">{t(`help.send.${key}`)}</p>
            </div>
          );
        });
      }
      case 'sell': {
        return Object.keys(sellProblems).map((key, index) => {
          return (
            <div
              key={index}
              id="0"
              className={
                'card answer-option ' +
                (categoryProblem === sellProblems[key]
                  ? 'help-answer-selected'
                  : '')
              }
              style={
                categoryProblem === sellProblems[key]
                  ? {
                      background: getMerchantColour(subdomainRetailer, null),
                      borderColor: getMerchantColour(subdomainRetailer, null),
                    }
                  : null
              }
              onClick={() => {
                setCategoryProblem(sellProblems[key]);
              }}
            >
              <p className="answer-option-label">{t(`help.sell.${key}`)}</p>
            </div>
          );
        });
      }
      case 'connection': {
        return Object.keys(connectionProblems).map((key, index) => {
          return (
            <div
              key={index}
              id="0"
              className={
                'card answer-option ' +
                (categoryProblem === connectionProblems[key]
                  ? 'help-answer-selected'
                  : '')
              }
              style={
                categoryProblem === connectionProblems[key]
                  ? {
                      background: getMerchantColour(subdomainRetailer, null),
                      borderColor: getMerchantColour(subdomainRetailer, null),
                    }
                  : null
              }
              onClick={() => {
                setCategoryProblem(connectionProblems[key]);
              }}
            >
              <p className="answer-option-label">
                {t(`help.connection.${key}`)}
              </p>
            </div>
          );
        });
      }
      case 'general': {
        return Object.keys(generalProblems).map((key, index) => {
          return (
            <div
              key={index}
              id="0"
              className={
                'card answer-option ' +
                (categoryProblem === generalProblems[key]
                  ? 'help-answer-selected'
                  : '')
              }
              style={
                categoryProblem === generalProblems[key]
                  ? {
                      background: getMerchantColour(subdomainRetailer, null),
                      borderColor: getMerchantColour(subdomainRetailer, null),
                    }
                  : null
              }
              onClick={() => {
                setCategoryProblem(generalProblems[key]);
              }}
            >
              <p className="answer-option-label">{t(`help.general.${key}`)}</p>
            </div>
          );
        });
      }
      case 'purchases': {
        return Object.keys(purchaseProblems).map((key, index) => {
          return (
            <div
              key={index}
              id="0"
              className={
                'card answer-option ' +
                (categoryProblem === purchaseProblems[key]
                  ? 'help-answer-selected'
                  : '')
              }
              style={
                categoryProblem === purchaseProblems[key]
                  ? {
                      background: getMerchantColour(subdomainRetailer, null),
                      borderColor: getMerchantColour(subdomainRetailer, null),
                    }
                  : null
              }
              onClick={() => {
                setCategoryProblem(purchaseProblems[key]);
              }}
            >
              <p className="answer-option-label">{t(`help.purchase.${key}`)}</p>
            </div>
          );
        });
      }
      default: {
        return null;
      }
    }
  };

  const handleSubmission = async () => {
    if (step === 2 || (fromPage === 'login' && step === 3)) {
      setStep((prevStep) => prevStep + 1);
    } else {
      try {
        let payload = {
          category: category,
          category_problem: categoryProblem,
          description: problemDescription,
          latitude: coordinates.latitude,
          longitude: coordinates.longitude,
          client_id: user?.details?.id ?? null,
          retailer_id: user?.currentRetailer?.id ?? null,
          type: 'application',
        };
        switch (fromPage) {
          case 'login': {
            let formNum = sendNum;
            formNum = formNum.replace(/\s/g, '');
            const validNumber = validateCell(formNum, code.code);
            if (!validNumber) {
              setErrors({
                number: 'Please enter a valid cell number',
                amount: null,
              });
              return;
            }
            if (sendNum[0] === '0') {
              formNum = formNum.substring(1);
            }
            if (validNumber) {
              formNum = code.code + formNum;
            }
            payload.cell_number = formNum;
            payload.source = href;
            break;
          }
          default: {
            payload.source = href;
          }
        }
        setLoading(true);
        const response = await createTicket(payload);
        setLoading(false);
        setStep(1);
        if (purchasesPath) {
          history.push(purchasesPath);
        } else if (captivePath) {
          history.push(captivePath);
        } else {
          history.push('/dashboard');
        }
        if (response.hasOwnProperty('error')) {
          throw response;
        }
        showToast('success', `Ticket submitted`);
      } catch (response) {
        showToast('error', 'Failed to create ticket');
      }
    }
  };

  return (
    <Fragment>
      <div className="client-header-container dashboard">
        <div className="dashboard-nav">
          <Link
            onClick={(e) => {
              if (fromPage) {
                e.preventDefault();
                history.goBack();
              }
            }}
            className="help-back"
            to={'/'}
          >
            <img
              alt=""
              className="dashboard-nav-back-arrow"
              src="assets/layout/images/icon-arrow-back.svg"
            />
          </Link>
          <p className="font-face-poppins-r header-top-text">
            {t('help.help')}
          </p>
          <p style={{ visibility: 'hidden' }}>{'x'}</p>
        </div>
      </div>
      <div
        className="help"
        style={fromPage === 'login' ? { paddingTop: 85 } : null}
      >
        {loading ? (
          <div className="help-progress-spinner">
            <ProgressSpinner />
          </div>
        ) : (
          <div className="card">
            <div className="p-grid p-fluid client-balance-inputs">
              <div className="p-col-12">
                <div className="help-btn-container">
                  <div className="questionnaire-container-body">
                    <div className="question-container">
                      <div className="question-label">
                        {outputHeading(step)}
                      </div>
                      <hr />
                      {step === 1 && outputCategories()}
                      {step === 2 && outputCategoryProblems(category)}
                      {step === 3 && (
                        <>
                          <InputTextarea
                            value={problemDescription}
                            onChange={(e) =>
                              setProblemDescription(e.target.value)
                            }
                            rows={10}
                            cols={10}
                            autoResize
                            maxLength={225}
                          />
                          <div
                            style={{ fontSize: '12px' }}
                          >{`${problemDescription.length}/225`}</div>
                        </>
                      )}
                      {step === 4 && (
                        <>
                          <CellInput
                            autocomplete={false}
                            code={code}
                            setCode={setCode}
                            number={sendNum}
                            setNumber={(value) => setSendNum(value)}
                            error={errors.number}
                            omitHeading={true}
                          />
                        </>
                      )}
                      <div className="question-count-label">
                        {t('help.step', {
                          step: step,
                          stepCount: fromPage === 'login' ? 4 : 3,
                        })}
                      </div>
                      <div className="button-container">
                        {step === 1 && (
                          <>
                            <button
                              className="p-button p-component question-btn "
                              style={
                                category === null
                                  ? {
                                      backgroundColor: '#B2B2B2',
                                      borderColor: '#B2B2B2',
                                    }
                                  : {
                                      background: getMerchantColour(
                                        subdomainRetailer,
                                        null,
                                      ),
                                      borderColor: getMerchantColour(
                                        subdomainRetailer,
                                        null,
                                      ),
                                    }
                              }
                              disabled={
                                step === 1 && category === null ? true : false
                              }
                              onClick={() => {
                                setStep((prevStep) => prevStep + 1);
                              }}
                            >
                              <span className="p-button-label p-c">
                                {t('help.next')}
                              </span>
                            </button>
                          </>
                        )}
                        {step > 1 && (
                          <>
                            <button
                              className="p-button p-component question-btn"
                              onClick={() => {
                                setStep((prevStep) => prevStep - 1);
                              }}
                              style={{
                                background: getMerchantColour(
                                  subdomainRetailer,
                                  null,
                                ),
                                borderColor: getMerchantColour(
                                  subdomainRetailer,
                                  null,
                                ),
                              }}
                            >
                              <span className="p-button-label p-c">
                                {t('help.back')}
                              </span>
                            </button>
                            <button
                              className="p-button p-component question-btn"
                              disabled={
                                step === 2 && categoryProblem === null
                                  ? true
                                  : step === 3 && problemDescription.length < 5
                                  ? true
                                  : (step === 4 &&
                                      String(sendNum).length < 10) ||
                                    String(sendNum).length > 10
                                  ? true
                                  : false
                              }
                              style={
                                step === 2 && categoryProblem === null
                                  ? {
                                      backgroundColor: '#B2B2B2',
                                      borderColor: '#B2B2B2',
                                    }
                                  : step === 3 && problemDescription.length < 5
                                  ? {
                                      backgroundColor: '#B2B2B2',
                                      borderColor: '#B2B2B2',
                                    }
                                  : (step === 4 &&
                                      String(sendNum).length < 10) ||
                                    String(sendNum).length > 10
                                  ? {
                                      backgroundColor: '#B2B2B2',
                                      borderColor: '#B2B2B2',
                                    }
                                  : {
                                      background: getMerchantColour(
                                        subdomainRetailer,
                                        null,
                                      ),
                                      borderColor: getMerchantColour(
                                        subdomainRetailer,
                                        null,
                                      ),
                                    }
                              }
                              onClick={handleSubmission}
                            >
                              <span className="p-button-label p-c">
                                {step === 2 ||
                                (fromPage === 'login' && step === 3)
                                  ? t('help.next')
                                  : t('help.submit')}
                              </span>
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default Help;
