import apiClient from '../../../api-config/axios.config';
import { handleInterceptor } from '../../../utilities/handleHTTPInterceptor';

const baseURL = '/v2/vulapay';
const flashURL = '/v2/flashpay';

const cache: { [key: string]: Promise<any> | undefined } = {};

type PaymentMethod = {
  name: string;
  min: number;
  bonus: number;
};

type QRClaim = {
  claimed: boolean;
  code: string;
  client_id: number;
};

export type PaymentType =
  | 'sale'
  | 'transfer'
  | 'purchase'
  | 'deposit'
  | 'donation';

export type PaymentStatus =
  | 'creating_payment'
  | 'payment_pending'
  | 'paid'
  | 'transfer'
  | 'purchasing'
  | 'complete'
  | 'failed';

export type PaymentRequest = {
  id: number;
  for_tribe: boolean;
  donation: boolean;
  payment_id: string;
  accountId: number;
  toAccountId?: number;
  receivingAccountId?: number;
  productId: number;
  productSlug: string;
  bundlePurchaseId?: number;
  paymentOptionId: number;
  transactionInId: number;
  transactionOutId: number;
  originAccountId: number;
  amount: number;
  amountPaid: number;
  vulacoin: number;
  vulacoinPaid: number;
  fee: number;
  connect_fee: number;
  pay_fee: number;
  commission: number;
  discount: number;
  fee_ex_Vat: number;
  fee_inc_Vat: number;
  gateway_fee: number;
  type: PaymentType;
  status: PaymentStatus;
  createdAt: Date;
  updatedAt: Date;
  clientId?: number;
  toClientId?: number;
  toRetailerId?: number;
  receivingClientId?: number;
  receivingRetailerId?: number;
  error?: any;
  extras:
    | {
        origin: 'app' | 'captive';
        accountNumber?: string;
        paymentMethod?: string;
        qrCodePayment?: {
          qrCodeUrl: string;
          supportedApplications: string[];
        };
        appPaymentLinks: {
          paymentUrl: string;
          paymentMethodName:
            | 'ZAPPER'
            | 'MASTERPASS'
            | 'NEDBANK_MONEY'
            | 'FNB_SCAN_TO_PAY'
            | 'NEDBANK_SCAN_TO_PAY'
            | 'TEST_MOBILE_qmnTQPJCka';
        }[];
        webPaymentLinks: {
          paymentUrl: string;
          paymentMethodName:
            | 'CARD'
            | 'CAPITEC_EFT'
            | 'EFT'
            | 'NEDBANK_EFT'
            | 'TEST_WEB_ukjObOxute';
        };
        home?: {
          assigned_bundle: boolean;
          bundle_purchase_id: number;
          home_devices: number;
        };
      }
    | any;
};

const FIVE_MINS = 5 * 60;

function clearCacheAfterSeconds(key: string, seconds = FIVE_MINS) {
  setTimeout(() => (cache[key] = undefined), seconds * 1000);
}

export function getPaymentMethods(
  paymentId: string,
  isPayment: boolean,
  retailerId: string | number,
  isTransfer: boolean = false,
): Promise<Array<PaymentMethod>> {
  const cacheKey = `getPaymentMethods-${isPayment}`;
  if (!cache[cacheKey]) {
    cache[cacheKey] = new Promise((resolve, reject) => {
      apiClient
        .get<Array<PaymentMethod>>(
          `${baseURL}/payments/payment-methods?paymentId=${paymentId}&isPayment=${isPayment}&retailerId=${retailerId}&isTransfer=${isTransfer}`,
        )
        .then(({ data }) => {
          resolve(data);
          clearCacheAfterSeconds(cacheKey);
          return data;
        })
        .catch((error) => {
          cache[cacheKey] = undefined;
          return handleInterceptor(error, error.message);
        });
    });
  }
  return cache[cacheKey]!;
}

export function getPayment(
  paymentId: string,
  paymentMethod?: string,
): Promise<PaymentRequest> {
  return apiClient
    .get<PaymentRequest>(
      `${
        paymentMethod === 'FlashPayments' ? flashURL : baseURL
      }/payments/${paymentId}`,
    )
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      return handleInterceptor(error, error.message);
    });
}

export function createPayment({
  retailerId,
  isPayment,

  donation,
  forTribe,
  paymentMethod,

  amount,
  extras,

  productId,
  productSlug,
  toClientId,
  accountId,
  toAccountId,
  toClientNumber,
}: {
  retailerId: number | undefined;
  isPayment: boolean;
  donation: boolean;
  forTribe: boolean;
  paymentMethod?: string;

  amount: number;
  extras: any;

  productId?: number;
  productSlug?: string;
  toClientId?: number;
  accountId?: number;
  toAccountId?: number;
  toClientNumber?: string;
}): Promise<PaymentRequest> {
  if (!extras) {
    extras = { domain: window.location.hostname };
  } else if (!extras.domain) {
    extras.domain = window.location.hostname;
  }
  return apiClient
    .post<PaymentRequest>(`${baseURL}/payments/create-payment`, {
      retailerId: Number(retailerId),
      isPayment,

      amount,

      donation,
      productId: Number(productId),
      productSlug,
      toClientId,
      accountId,
      toAccountId,
      paymentMethod,
      forTribe,
      extras,
      toClientNumber,
    })
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      return handleInterceptor(error, error.message);
    });
}
export function createTransferPayment({
  amount,
  toClientId,
  extras,
}: {
  amount: number;
  toClientId: number;
  extras: any;
}): Promise<PaymentRequest> {
  return apiClient
    .post<PaymentRequest>(`${baseURL}/payments/create-transfer-payment`, {
      amount,
      toClientId,
      extras,
    })
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      return handleInterceptor(error, error.message);
    });
}

export function updatePayment(
  paymentId: string,
  extras: any,
): Promise<PaymentRequest> {
  return apiClient
    .post<PaymentRequest>(`${baseURL}/payments/update-payment`, {
      paymentId,
      extras,
    })
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      return handleInterceptor(error, error.message);
    });
}

export function updatePaymentMethod(
  paymentId: string,
  paymentMethod: string,
  extras?: any,
): Promise<PaymentRequest> {
  return apiClient
    .post<PaymentRequest>(`${baseURL}/payments/update-payment-method`, {
      paymentId,
      paymentMethod,
      extras,
    })
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      return handleInterceptor(error, error.message);
    });
}

export function completePayment(
  paymentId: string,
  paymentMethod: string,
): Promise<PaymentRequest> {
  return apiClient
    .post<PaymentRequest>(
      `${
        paymentMethod === 'FlashPayments' ? flashURL : baseURL
      }/payments/complete-payment`,
      {
        paymentId,
      },
    )
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      return handleInterceptor(error, error.message);
    });
}

export function processPayment(paymentId: string): Promise<PaymentRequest> {
  return apiClient
    .post<PaymentRequest>(`${baseURL}/payments/process-payment`, {
      paymentId,
    })
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      return handleInterceptor(error, error.message);
    });
}

export function restartPayments(paymentId: string): Promise<PaymentRequest> {
  return apiClient
    .post<PaymentRequest>(`${baseURL}/payments/restart-payment`, {
      paymentId,
    })
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      return handleInterceptor(error, error.message);
    });
}

export function cancelPayment(
  paymentId: string,
  reason: string,
): Promise<PaymentRequest> {
  return apiClient
    .post<PaymentRequest>(`${baseURL}/payments/cancel-payment`, {
      paymentId,
      reason,
    })
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      return handleInterceptor(error, error.message);
    });
}

export function addMarketplacePayment(
  paymentId: string,
  marketplace_cart: any,
): Promise<PaymentRequest> {
  return apiClient
    .post<PaymentRequest>(`${baseURL}/payments/update-payment`, {
      paymentId,
      extras: {
        marketplace_cart,
      },
    })
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      return handleInterceptor(error, error.message);
    });
}

export function createOrFindQr(uuid: string): Promise<QRClaim> {
  return apiClient
    .post<QRClaim>(`${baseURL}/qr-codes`, {
      code: uuid,
    })
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      return handleInterceptor(error, error.message);
    });
}
