import { ProgressSpinner } from 'primereact/progressspinner';
import { lazy, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import Help from './Client/pages/Help';
import ClientLoginCaptive from './Client/pages/ClientLoginCaptive';
import { QRCodeCreate } from './Retailer/pages/QRCodeCreate';
import {
  cancelPayment,
  getPayment,
} from './Client/service/api/payments.service';

const ForgotPassword = lazy(
  () => import('./Pages/ForgotPassword/ForgotPassword'),
);
const AdminLogin = lazy(() => import('./Admin/pages/AdminLogin'));
const ClientLogin = lazy(() => import('./Client/pages/ClientLogin'));

const AdminApp = lazy(() => import('./Admin/App'));
const RetailerApp = lazy(() => import('./Retailer/App'));
const ChangePassword = lazy(
  () => import('./Retailer/pages/ChangePassword/ChangePassword'),
);
const ClientApp = lazy(() => import('./Client/App'));
const StatsApp = lazy(() => import('./Stats'));
const ResetPassword = lazy(() => import('./Pages/ResetPassword/ResetPassword'));

const MarketPlaceApp = lazy(() => import('./Marketplace'));

const LoadingPage = () => (
  <div className="max-div">
    <ProgressSpinner />
  </div>
);

let newCaptiveFlow = false;

try {
  newCaptiveFlow = localStorage?.getItem('useNewCaptiveFlow') === 'true';
} catch {}

const RedirectPayment = () => {
  const params: any = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop: string) => searchParams.get(prop),
  });

  const paymentID = params.paymentId;
  const domain = params.domain;
  const vc_status = params.vc_status;

  const redirect = () => {
    if (params.captive_redirect === '1') {
      getPayment(paymentID).then((payment) => {
        if (!!payment?.extras?.redirect_link) {
          window.location.href = `${decodeURIComponent(
            payment.extras.redirect_link,
          )}&paymentId=${paymentID}`;
        }
      });
    } else {
      window.location.href = `https://${
        domain || window.location.hostname
      }/payment-status?paymentId=${paymentID}`;
    }
  };

  useEffect(() => {
    if (vc_status !== 'paid') {
      const reason = params.digiapiResponseMsg;
      cancelPayment(paymentID, reason).then(() => {
        redirect();
      });
    } else {
      redirect();
    }
  }, []);

  return (
    <div className="max-div">
      <ProgressSpinner />
    </div>
  );
};

const routes = [
  {
    name: 'payment-redirect',
    path: '/payment-redirect',
    component: RedirectPayment,
  },
  {
    name: 'Stats',
    path: '/stats',
    component: StatsApp,
  },
  {
    name: 'forgot-password',
    path: '/admin/forgot-password',
    component: ForgotPassword,
    exact: true,
    loggedIn: false,
  },
  {
    name: 'retailer-reset-password',
    path: '/reset-password',
    component: ResetPassword,
  },

  {
    name: 'admin-login',
    path: '/admin/login',
    component: AdminLogin,
    exact: true,
    loggedIn: false,
  },

  {
    name: 'marketplace-login-redirect',
    path: '/marketplace',
    component: () => <Redirect to="/admin/login" />,
    exact: false,
    loggedIn: false,
  },

  {
    name: 'marketplace',
    path: '/retailer/marketplace',
    component: MarketPlaceApp,
    exact: false,
    loggedIn: true,
  },

  {
    name: 'marketplace',
    path: '/admin/marketplace',
    component: MarketPlaceApp,
    exact: false,
    loggedIn: true,
  },
  {
    name: 'client-login',
    path: '/login',
    component: (props: any) => (
      <div className="client-app">
        <ClientLogin {...props} />
      </div>
    ),
    exact: true,
    loggedIn: false,
  },
  {
    name: 'generate-qr',
    path: '/retailer/qr',
    component: QRCodeCreate,
    exact: true,
    loggedIn: true,
  },

  {
    name: 'help',
    path: '/help',
    component: (props: any) => (
      <div className="client-app">
        <Help {...props} />
      </div>
    ),
    exact: true,
    loggedIn: false,
  },

  {
    name: 'admin-login-redirect',
    path: '/admin',
    component: () => <Redirect to="/admin/login" />,
    exact: false,
    loggedIn: false,
  },
  {
    name: 'retailer-login-redirect',
    path: '/retailer',
    component: () => <Redirect to="/admin/login" />,
    exact: false,
    loggedIn: false,
  },
  {
    name: 'client-login-redirect',
    path: '',
    component: () => {
      let path = '/login';
      if (!['/login', '/dashboard', '/'].includes(window.location.pathname)) {
        path += `?intendedUrl=${window.location.href}`;
      }
      return <Redirect to={path} />;
    },
    exact: false,
    loggedIn: false,
  },
  {
    name: 'client-defer',
    path: ['', '/captive/:hash', '/manage-devices', '/products'],
    component: LoadingPage,
    exact: false,
    deferDashboard: true,
  },

  {
    name: 'admin',
    path: '/admin',
    component: AdminApp,
    exact: false,
    loggedIn: true,
  },

  {
    name: 'retailer-change-password',
    path: '/retailer',
    component: ChangePassword,
    emailVerify: false,
    exact: false,
  },
  {
    name: 'retailer',
    path: '/retailer',
    component: RetailerApp,
    exact: false,
    emailVerify: true,
    loggedIn: true,
  },

  {
    name: 'client',
    path: '',
    component: ClientApp,
    exact: false,
    loggedIn: true,
  },
];

export default routes;
