export interface ErrorResponse {
  error: boolean;
  revoke: boolean;
  message: string;
  data: unknown;
  code: number;
}

export const httpInterceptor = (code: number, message: string, data = null) => {
  switch (code) {
    case 401:
      return { error: true, revoke: true, message, code };
    default:
      return { error: true, revoke: false, message, data, code };
  }
};
