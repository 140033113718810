// noinspection JSCheckFunctionSignatures
import React, {
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  withRouter,
} from 'react-router-dom';
import URLParse from 'url-parse';

import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';

import sentryErrorHandler from './utilities/sentryErrorHandler';

import UserContext from './Context/UserContext';
import PaymentContext from './Context/PaymentContext';

import { getCookie, whoAmI } from './Admin/service/api/auth';

import { loginFromRedirect } from './Client/service/api/clientAuth';
import { fetchRetailerByCaptiveSubdomain } from './Client/service/api/retailers';

import { onLogin } from './utilities/general';

import routes from './routes';
import { getPaymentRequestedFrom } from './Client/service/api/payments';
import { getClientNotifications } from './Client/service/api/notifications';
import { PushNotification } from './common/Components/Notifications/PushNotification';
import { getMyTribe } from './Client/service/api/tribes';
import { useTranslation } from 'react-i18next';
import { injectMerchantStyles } from './utilities/merchantStyles';
import DetectHotspot from './Client/components/DetectHotspot/DetectHotspot';
import { requestSubdomainRetailer } from './Client/service/api/clientAuth';
import { jwtDecode } from 'jwt-decode';
import { jwt } from './api-config/jwt-session';
import moment from 'moment';

const AppWrapper = () => {
  const { t } = useTranslation();
  let location = useLocation();
  const history = useHistory();

  const toast = useRef(null);

  const [user, setUserState] = useState({
    details: '',
    role: '',
    permissions: [],
  });

  const [paymentDetail, setPaymentDetail] = useState(null);
  const [phasedPaymentId, setPhasedPaymentId] = useState(null);
  const [showLogout, setShowLogout] = useState(false);
  const [validating, setValidating] = useState(true);
  const [showRevoked, setShowRevoked] = useState(false);
  const [deferDashboard, setDeferDashboard] = useState(false);
  const [incomingUrl] = useState(URLParse(window.location).pathname);
  const [incomingQueryParams] = useState(URLParse(window.location).query);
  const [incomingUrlHash] = useState(URLParse(window.location).hash);
  const [notification, setNotification] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [showClientSideMenu, setShowClientSideMenu] = useState(false);
  const [showSwitchRetailer, setShowSwitchRetailer] = useState(false);
  const [paymentStep, setPaymentStep] = useState('');

  const setUser = useCallback((user) => {
    setUserState(user);
    injectMerchantStyles(user.currentRetailer);
  }, []);

  let payment_id = history.location.search
    ? history.location.search.split('requestId=')[1]
      ? history.location.search.split('requestId=')[1].split('&')[0]
      : ''
    : '';

  const params = new URLSearchParams(window.location.search);
  if (
    params.get('externalReference') &&
    params.get('externalReference') !== 'null'
  ) {
    payment_id = params.get('externalReference');
  }

  const checkNotifications = (res) => {
    let currentUser = res;
    if (currentUser.role === 'client' && !notification) {
      getClientNotifications()
        .then((data) => {
          if (data.data.notification) {
            setNotification(data.data.notification);
            setShowNotification(true);
          }
        })
        .catch((e) => {});

      setInterval(async () => {
        getClientNotifications()
          .then((data) => {
            if (data.data.notification) {
              setNotification(data.data.notification);
              setShowNotification(true);
            }
          })
          .catch((e) => {});
      }, 300000);
    }
  };

  const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];

  if (path) {
    history.replace(path);
  }
  const redirectToCaptiveFromCaptiveSubdomain = async () => {
    const x = window.location.host.split('.');
    const y = x[0].split('-')[1];
    if (y === 'captive') {
      try {
        const retailer = await fetchRetailerByCaptiveSubdomain(x[0]);
        x.shift();
        window.location.replace(
          `${window.location.protocol}//${x.join('.')}/captive/${
            retailer.hash
          }${window.location.search}`,
        );
        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    }
    return false;
  };

  redirectToCaptiveFromCaptiveSubdomain().then();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (showClientSideMenu || showSwitchRetailer) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'scroll';
    }
  }, [showClientSideMenu, showSwitchRetailer]);

  const getCookieAndCheckUser = (nc) => {
    getCookie().then(() => {
      if (payment_id) {
        authFromRedirect(nc);
      } else {
        checkUser();
      }
    });
  };

  const getExternalReference = (payment_gateway_response_id) => {
    getPaymentRequestedFrom(payment_gateway_response_id)
      .then((data) => {
        const requestedFrom = data.requested_from_hostname;
        if (requestedFrom !== window.location.hostname) {
          const redirect = window.location.href.replace(
            window.location.hostname,
            requestedFrom,
          );
          window.location.href = redirect;
        } else {
          getCookieAndCheckUser(true);
        }
      })
      .catch((e) => {
        if (e.response && e.response.status === 404) {
          history.push('');
        } else {
          setTimeout(
            () => getExternalReference(payment_gateway_response_id),
            5000,
          );
        }
      });
  };

  useEffect(() => {
    if (params.get('externalReference')) {
      const payment_gateway_response_id = params.get('id');
      getExternalReference(payment_gateway_response_id);
    } else {
      getCookieAndCheckUser();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const checkUser = async () => {
    const admin = location.pathname.indexOf('/admin') !== -1;
    const isRetailer = location.pathname.indexOf('/retailer') !== -1;
    const ppId = new URLSearchParams(window.location.search).get('paymentId');
    if (window.location.pathname === '/payments' && ppId) {
      setPhasedPaymentId(ppId);
    }

    if (!admin && !isRetailer) {
      const r = await requestSubdomainRetailer().catch((e) => ({}));
    }

    whoAmI(admin, isRetailer, ppId).then(async (res) => {
      if (!res.error) {
        const user = {
          details: res.user,
          role: res.role,
          permissions: res.permissions,
          currentRetailer: res.currentRetailer,
        };

        if (res.role === 'client') {
          const tribe = await getMyTribe().catch(() => []);
          user.tribe = tribe;
          checkNotifications(res);
        }

        setUser(user);
        onLogin(res);
        const decode = jwtDecode(jwt.token());

        if (decode?.wait_time_seconds > 0) {
          history.replace('waiting-room');
        }
      }

      setValidating(false);
    });
  };

  const authFromRedirect = (nc) => {
    loginFromRedirect(payment_id, nc).then(async (res) => {
      if (!res.error) {
        setPaymentDetail(res.payment_details);

        const user = {
          details: res.user,
          role: res.role,
          currentRetailer: res.payment_details
            ? res.payment_details.retailer
            : {},
        };

        if (res.role === 'client') {
          const tribe = await getMyTribe().catch(() => []);
          user.tribe = tribe;
        }

        setUser(user);

        window.pushUserToDataLayer(res.user);
      } else {
        handleError(res);
      }

      if (res.code && [404, 400, 200].indexOf(res.code) === -1) {
        if (res.code === 419) {
          window.location.reload();
        } else {
          setTimeout(() => authFromRedirect(nc), 5000);
        }
      } else {
        setValidating(false);
      }
    });
  };

  const logoutUser = () => {
    let path = '';

    if (/\/(admin|retailer)/.test(location.pathname)) {
      path = 'admin';
    }

    setShowLogout(true);
    setUser({ details: '', role: '', permissions: [], currentRetailer: {} });
    history.replace(path);
  };

  const showToast = useCallback(
    (type, header, message, time) => {
      if (toast && toast.current) {
        toast.current.show({
          severity: type,
          summary: t(header),
          detail: t(message),
          life: time ? time : 3000,
        });
      } else {
        setTimeout(() => {
          if (toast && toast.current) {
            toast.current.show({
              severity: type,
              summary: t(header),
              detail: t(message),
              life: time ? time : 3000,
            });
          } else {
            console.error('toast.current is null');
          }
        }, 2000);
      }
    },
    [t],
  );

  const handleError = useCallback(
    (data, time) => {
      if (data === 'Unauthenticated.') {
        history.push(`login`);
      }
      if (data.revoke) {
        setShowRevoked(true);
        setUser({
          details: '',
          role: '',
          permissions: [],
          currentRetailer: {},
        });
      } else {
        let message = data.message;
        if (Array.isArray(data.message)) {
          message = data.message.join('\n');
        }
        showToast('error', 'Error', message, time);
      }
    },
    [history, showToast],
  );

  const LoadingPage = (props) => (
    <div className="max-div">
      <ProgressSpinner />
    </div>
  );

  if (validating) {
    return <LoadingPage />;
  }

  const isLoggedIn = !!user.details;
  const verified = !!user?.details?.email_verified_at;

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <PaymentContext.Provider
        value={{ paymentDetail, setPaymentDetail, phasedPaymentId }}
      >
        <Toast ref={toast} />
        {user?.role === 'client' && (
          <DetectHotspot retailer={user.currentRetailer} />
        )}
        <PushNotification
          user={user}
          setNotification={setNotification}
          notification={notification}
          show={showNotification}
          setShow={setShowNotification}
        />
        <Suspense fallback={<LoadingPage />}>
          <Switch>
            {routes
              .filter(
                (route) =>
                  (typeof route.loggedIn === 'undefined' ||
                    route.loggedIn === isLoggedIn) &&
                  (typeof route.emailVerify === 'undefined' ||
                    route.emailVerify === verified) &&
                  (!route.deferDashboard ||
                    route.deferDashboard === deferDashboard),
              )
              .map((route) => (
                <Route
                  key={route.name}
                  path={route.path}
                  exact={route.exact}
                  render={() => (
                    <route.component
                      {...{
                        setDeferDashboard,
                        deferDashboard,
                        logout: logoutUser,
                        showToast,
                        handleError,
                        isLoggedIn,
                        incomingParams: incomingUrl,
                        incomingQuery: incomingQueryParams,
                        incomingUrlHash: incomingUrlHash,
                        showLogout,
                        setShowLogout,
                        showRevoked,
                        showClientSideMenu,
                        setShowClientSideMenu,
                        setPaymentStep,
                        paymentStep,
                        showSwitchRetailer,
                        setShowSwitchRetailer,
                      }}
                    />
                  )}
                />
              ))}
          </Switch>
        </Suspense>
      </PaymentContext.Provider>
    </UserContext.Provider>
  );
};

export default sentryErrorHandler.errorHandler(
  withRouter(AppWrapper),
  'AppWrapper',
);
